import "../styles/global.scss";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useAnalytics from "../hooks/useAnalytics";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, trackPageView]);

  return <Component {...pageProps} />;
}
