type LinkPlacementType = "main_menu" | "footer_menu" | "banner" | "other";
type ButtonCategoryType =
  | "download"
  | "submit"
  | "ui interaction"
  | "navigation";

declare global {
  interface Window {
    utag?: any;
    _st?: any;
  }
}

export default function useAnalytics() {
  function trackPageView(name: string) {
    if (window?.utag) {
      window.utag.view({
        tealium_event: "page_view",
        page_type: "page",
        page_url: name,
      });
    }

    if (window._st) {
      // Set pagestructure
      const r42_pageStructure = location.pathname.slice(1).replace(/\//g, "|");
      // Relay42 settings
      window._st("resetTags");
      window._st("setStructurePrefix", "porsche", "garagementality");
      window._st("setPageStructure", r42_pageStructure);
      window._st("loadTags");
    }
  }

  function trackLinkClick(
    type: "internal" | "external",
    url: string,
    text: string,
    placement: LinkPlacementType
  ) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "link_click",
        linkType: type,
        linkUrl: url,
        linkText: text,
        linkPlacement: placement,
      });
    }
  }

  function trackButtonClick(
    categoryType: ButtonCategoryType,
    url: string,
    text: string
  ) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "button_click",
        buttonCategory: categoryType,
        buttonUrl: url,
        buttonText: text,
        eventCategory: "buttons",
        eventAction: categoryType,
        eventLabel: url,
      });
    }
  }

  function trackFormSubmit(
    url: string,
    formName: string,
    formSubject: string,
    fields: { [key: string]: string }
  ) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "form_submit",
        pageUrl: url,
        brand: process.env.NEXT_PUBLIC_TEALIUM_BRAND,
        eventCategory: formName,
        eventAction: formSubject,
        eventLabel: "submit",
        formName: formName,
        formAction: "",
        ...fields,
      });
    }
  }

  function trackFormFocus(
    url: string,
    formName: string,
    formSubject: string,
    fields: { [key: string]: string }
  ) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "form_focus_change",
        pageUrl: url,
        brand: process.env.NEXT_PUBLIC_TEALIUM_BRAND,
        eventCategory: formName,
        eventAction: formSubject,
        eventLabel: "focus change",
        formName: formName,
        formAction: "",
        ...fields,
      });
    }
  }

  function trackFormError(
    url: string,
    formName: string,
    formSubject: string,
    fieldName: string,
    fieldError: string
  ) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "form_error",
        pageUrl: url,
        brand: process.env.NEXT_PUBLIC_TEALIUM_BRAND,
        eventCategory: formName,
        eventAction: formSubject,
        eventLabel: "error",
        formName: formName,
        formAction: "",
        formFieldError: fieldName,
        formErrorMessage: fieldError,
      });
    }
  }

  function trackFormStart(url: string, formName: string, formSubject: string) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "form_start",
        pageUrl: url,
        brand: process.env.NEXT_PUBLIC_TEALIUM_BRAND,
        eventCategory: formName,
        eventAction: formSubject,
        eventLabel: "start",
        formName: formName,
        formAction: "",
      });
    }
  }

  function trackFormStep(
    url: string,
    formName: string,
    formSubject: string,
    formStep: number,
    formStepName: string
  ) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "form_step",
        pageUrl: url,
        brand: process.env.NEXT_PUBLIC_TEALIUM_BRAND,
        eventCategory: formName,
        eventAction: formSubject,
        eventLabel: `step ${formStep}`,
        carModel: "",
        carModelStyle: "",
        formName: formName,
        formAction: "",
        formStep,
        formStepName,
      });
    }
  }

  function trackFormSuccess(
    url: string,
    formName: string,
    formSubject: string
  ) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "form_succes",
        pageUrl: url,
        brand: process.env.NEXT_PUBLIC_TEALIUM_BRAND,
        eventCategory: formName,
        eventAction: formSubject,
        eventLabel: "succes",
        carModel: "",
        carModelStyle: "",
        formName: formName,
        formAction: "",
      });
    }
  }

  function trackAIInteraction(interactionType: string) {
    if (window?.utag) {
      window.utag.link({
        tealium_event: "ai_chat_interaction",
        eventCategory: "ai_chat",
        eventAction: "interaction",
        eventLabel: interactionType,
        typeOfInteraction: interactionType,
      });
    }
  }

  return {
    trackPageView,
    trackLinkClick,
    trackButtonClick,
    trackFormSubmit,
    trackFormFocus,
    trackFormError,
    trackFormStart,
    trackFormStep,
    trackFormSuccess,
    trackAIInteraction,
  };
}
